'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.dispatch.factory:DispatchUnitContactTypesManager

 # @description

###
angular
.module 'mundoAdmin.dispatch'
.factory 'DispatchUnitContactTypesManager', [
  'MundoDefaultManager'
  'TenantManager'
  (MundoDefaultManager, TenantManager)->
    DispatchUnitContactTypesManagerBase = new MundoDefaultManager()
    DispatchUnitContactTypesManagerBase.setAutoTenant()
    DispatchUnitContactTypesManagerBase.setUrl('lpa/dispatch/units/contacts/types')
    DispatchUnitContactTypesManagerBase.setNewObject(['label', 'code'])
    DispatchUnitContactTypesManagerBase.setUpdateObject(['label', 'code'])
    DispatchUnitContactTypesManagerBase.setSearchFields(['label', 'code'])

    DispatchUnitContactTypesManagerBase.getNewForm = ->
      [
        key: 'label'
        name: 'label'
        type: 'input'
        templateOptions:
          label: 'Label'
          placeholder: 'label'
          required: true
      ,
        key: 'code'
        name: 'code'
        type: 'input'
        templateOptions:
          label: 'Code'
          placeholder: 'code'
          required: true
      ]

    DispatchUnitContactTypesManagerBase.getEditForm = (data) ->
      [
        key: 'label'
        name: 'label'
        type: 'input'
        defaultValue: data.label
        templateOptions:
          label: 'Label'
          placeholder: 'label'
          required: true
      ,
        key: 'code'
        name: 'code'
        defaultValue: data.code
        type: 'input'
        templateOptions:
          label: 'Code'
          placeholder: 'code'
          required: true
      ]

    DispatchUnitContactTypesManagerBase.getListSchema = ->
      [
        key: 'label'
        title: 'datatable.label.label'
        sort: 'label'
      ,
        key: 'code'
        title: 'datatable.label.code'
        sort: 'code'
      ]

    DispatchUnitContactTypesManagerBase.editPermission =
      'manage all MundoMosaLpaDispatchingBundle:DispatchUnitContactType entities'

    DispatchUnitContactTypesManagerBase
]
